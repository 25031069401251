@use 'src/shared/styles/colors.scss';
@use 'src/shared/styles/mediaStyles.scss';
@use 'src/shared/styles/variables.scss';

.login-container {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 0 variables.$baseMargin;
  width: auto;
  margin: 0 auto;
  min-width: 350px;
  max-width: 820px;

  .navigate-back {
    position: relative;
    z-index: 10;
    top: 60px;
    font-size: 1.2rem;
    margin-left: -10px;
  }

  .description {
    font-size: 1.1rem;
    max-width: 400px;
    margin-bottom: 20px;
  }

  .CP-input {
    margin-bottom: 1.75em;
  }

  .CP-button {
    width: 200px;
    padding-top: 2em;
    padding-bottom: 1em;
  }

  h1 {
    margin-top: 4rem;
  }

  .login-form {
    display: flex;
    flex-direction: column;
    max-width: 400px;

    label {
      opacity: 0.5;
      margin-bottom: 8px;
    }
  }
}

.password-reset {
  .description {
    max-width: 850px;
  }
}

@media (max-width: (mediaStyles.$largerPhone)) {
  .login-container {
    margin: 4rem auto 0;

    .description {
      font-size: 1.4rem;
    }

    .navigate-back {
      position: absolute;
      margin-top: 2rem;
      margin-bottom: 20px;
    }

    h1 {
      margin-top: 8rem;
    }
  }
}

@media (min-width: (mediaStyles.$largerPhone + 1)) and (max-width: mediaStyles.$tablet) {
  .login-container {
    margin: 0 auto;
    max-width: 450px;

    .navigate-back {
      font-size: 1.2rem;
      margin-top: 6rem;
      margin-bottom: 40px;
    }

    h1 {
      margin-top: 1rem;
    }
  }
}

@media (min-width: (mediaStyles.$tablet + 1)) {
  .login-container {
    margin: 4rem auto 0;

    .navigate-back {
      position: absolute;
      margin-top: -1.8rem;
      margin-bottom: 20px;
    }
  }
}
