@use 'src/shared/styles/colors.scss';
@use 'src/shared/styles/variables.scss';
@use 'src/shared/styles/mediaStyles.scss';

.card {
  border-radius: 4px;
  box-shadow: 0 8px 20px -10px colors.$beige_dark;
  background: #fff;
  font-size: 1rem;

  &.glass {
    @include variables.blurry-background();
    background: linear-gradient(110deg, rgba(255, 255, 255, 0.528) 17%, rgba(255, 255, 255, 0.088) 112%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.big {
    height: 20rem;
  }
}

.text-link {
  color: colors.$black_dark;

  &:hover {
    color: colors.$gray;
  }
}

.input-space {
  width: 100%;
}

@media (max-width: (mediaStyles.$tablet - 1)) {
  .card.big {
    height: 15rem;
  }
}
