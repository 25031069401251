@use 'src/shared/styles/colors.scss';
@use 'src/shared/styles/mediaStyles.scss';

@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file + '.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
}

@include font('GT America', './shared/styles/fonts/GT-America-Standard-Regular');
@include font('Recife Text', './shared/styles/fonts/RecifeText-SemiBold');

html {
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.02em;

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: colors.$beige_extra_light;
    font-family: 'GT America', sans-serif;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: 'Recife Text', serif;
    font-style: normal;
    font-weight: 600;
    color: colors.$black_dark;
  }

  h1 {
    font-size: 3.75rem;
    line-height: 5rem;
  }

  h2 {
    font-size: 2.25rem;
    line-height: 4rem;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 3rem;
  }

  a {
    color: colors.$teal;
  }

  a:hover {
    color: colors.$gray;
  }
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  overflow: clip;
  font-size: 1rem;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -600px;
    right: -680px;
    width: 400px;
    height: 2000px;
    transform: rotate(-45deg);
    border: 1.5px solid colors.$gray;
    border-radius: 40%;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: -120px;
    right: -140px;
    width: 240px;
    height: 240px;
    border: 1.5px solid colors.$orange;
    border-radius: 100%;
  }
}

@media (max-width: (mediaStyles.$largerPhone - 1)) {
  html {
    font-size: 12px;

    h1 {
      font-size: 2.85rem;
      line-height: 3.4rem;
    }

    h3 {
      font-size: 2.35rem;
      line-height: 3.2rem;
    }
  }
}

@media (min-width: mediaStyles.$largerPhone) {
  html {
    font-size: 13px;

    h1 {
      font-size: 2.85rem;
      line-height: 4.275rem;
    }
  }

  .app-container::before {
    right: -550px;
    transform: rotate(-40deg);
  }

  .app-container::after {
    width: 280px;
    height: 280px;
  }
}

@media (min-width: mediaStyles.$small) {
  html {
    font-size: 13px;

    h1 {
      font-size: 2.85rem;
      line-height: 4.275rem;
    }
  }

  .app-container::before {
    right: -485px;
  }

  .app-container::after {
    width: 400px;
    height: 400px;
    top: -175px;
    right: -215px;
  }
}

@media (min-width: mediaStyles.$tablet) {
  html {
    font-size: 14px;

    h1 {
      font-size: 3rem;
      line-height: 4.275rem;
    }

    h3 {
      font-size: 1.75rem;
    }
  }
}

@media (min-width: mediaStyles.$desktop) {
  html {
    font-size: 15px;

    h1 {
      font-size: 3.5rem;
      line-height: 4.5rem;
    }
  }

  .app-container::before {
    right: -320px;
  }

  .app-container::after {
    width: 900px;
    height: 900px;
    top: -400px;
    right: -670px;
  }
}

@media (min-width: mediaStyles.$desktopLarge) {
  html {
    font-size: 16px;

    h1 {
      font-size: 3.75rem;
      line-height: 5rem;
    }
  }
}

.ant-modal-mask {
  background-color: colors.$beige_dark !important;
  opacity: 0.9;
}
