@use 'src/shared/styles/variables.scss';
@use 'src/shared/styles/mediaStyles.scss';

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: variables.$baseMargin;
  width: 100%;

  &.browser {
    position: absolute;
    z-index: 5;
    padding: variables.$baseMargin 60px variables.$baseMargin variables.$baseMargin;
  }

  .hopkins-logo {
    cursor: pointer;
  }

  .logout-container {
    padding-bottom: 2px;
  }
}

@media (max-width: (mediaStyles.$largerPhone - 1)) {
  .header-container {
    .hopkins-logo {
      width: 110px;
      height: 100%;
    }
  }
}

@media (min-width: mediaStyles.$largerPhone) {
  .header-container {
    .hopkins-logo {
      width: 100px;
    }
  }
}

@media (min-width: mediaStyles.$tablet) {
  .header-container {
    .hopkins-logo {
      width: 110px;
    }
  }
}

@media (min-width: mediaStyles.$desktop) {
  .header-container {
    .hopkins-logo {
      width: 120px;
    }
  }
}

@media (min-width: mediaStyles.$desktopLarge) {
  .header-container {
    .hopkins-logo {
      width: 137px;
    }
  }
}
