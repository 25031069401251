.breadcrumbs-container {
  display: flex;
  align-items: center;
  line-height: 1.5rem;

  .breadcrumbs-icon {
    font-size: 0.8em;
    margin: 0 0.6em;
  }
}
