@use 'src/shared/styles/colors.scss';
@use 'src/shared/styles/fonts/fonts.scss';
@use 'src/shared/styles/mediaStyles.scss';

.password-tips {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 10px;
  font-size: 0.98rem;

  .password-instruction-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4px;
  }

  .check-icon {
    color: colors.$teal;
    border-radius: 20px;
    background: white;
  }

  .close-icon {
    @include fonts.supporting-text() ;
  }

  .password-instruction-text {
    margin-left: 10px;
  }

  .password-instruction-text:not(.isFulfilled) {
    @include fonts.supporting-text() ;
  }

  .isFulfilled {
    color: colors.$black_dark;
  }

  &.isBlurred {
    color: colors.$red;
    opacity: unset !important;

    .close-icon {
      color: colors.$red;
      opacity: unset !important;
    }

    .password-instruction-text {
      opacity: unset !important;
    }
  }
}

@media (max-width: (mediaStyles.$largerPhone)) {
  .password-tips {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    font-size: 0.98rem;
  }

  .password-instruction-item {
    width: 50%;
  }
}
