@use 'src/shared/styles/colors.scss';

.input-container {
  // remove google chrome input autofill coloring
  input:-webkit-autofill,
  input:-webkit-autofill:hover {
    box-shadow: 0 0 0 1000px colors.$beige_light inset;
    border: 1px solid colors.$beige_light;
  }

  input:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px white inset !important;
    border: 1px solid colors.$black_dark;
  }

  // next two for when email field is empty
  .ant-input[disabled] {
    background-color: colors.$beige_light;
  }

  .ant-input:hover {
    border: 1px solid transparent;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid transparent;
  }

  .ant-input-affix-wrapper-focused {
    background: white !important;
    box-shadow: none;
    border: 1px solid colors.$black_dark !important;
  }

  .CP-input {
    background: colors.$beige_light;
    border: 1px solid transparent;
    padding: 12px;

    &:focus {
      background: white;
      border: 1px solid colors.$black_dark;
      box-shadow: none;
    }
  }
}
