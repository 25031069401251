@use 'src/shared/styles/colors.scss';

.input-container {
  // remove google chrome input autofill coloring
  input:-webkit-autofill,
  input:-webkit-autofill:hover {
    box-shadow: 0 0 0 1000px colors.$beige_light inset;
    border: 1px solid colors.$beige_light;
  }

  input:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px white inset !important;
    border: 1px solid colors.$black_dark;
  }

  // next two for when email field is empty
  .ant-input[disabled] {
    background-color: colors.$beige_light;
  }

  .ant-input:hover {
    border: 1px solid transparent;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-focused) {
    background: colors.$beige_light !important;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid transparent;
  }

  .ant-input-affix-wrapper-focused {
    background: white !important;
    box-shadow: none;
    border: 1px solid colors.$black_dark !important;
    padding-right: 0;
  }

  .password-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    .CP-input-password {
      background: colors.$beige_light;
      border: 1px solid transparent;
      padding: 0 12px 0 0;

      // because antd password adds an input within the input to wrap the suffix icon
      input { /* stylelint-disable-line */
        background: colors.$beige_light;
        border: none !important;
        padding: 12px !important;

        // for when field is empty
        &:focus {
          background: white;
        }
      }

      input:-webkit-autofill:focus {
        box-shadow: 0 0 0 1000px white inset !important;
        border: 1px solid colors.$black_dark;
      }
    }

    .password-check-icon {
      position: absolute;
      top: 50%;
      right: 40px;
      z-index: 10;
      font-size: 20px;
      margin-top: -10px;
      margin-left: 12px;
      border-radius: 20px;
      color: colors.$teal;
      background: white;
    }
  }
}
