@use 'src/shared/styles/colors.scss';

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  .loader {
    color: colors.$black_light;
  }
}
