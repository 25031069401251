@use 'src/shared/styles/colors.scss';
@use 'src/shared/styles/variables.scss';

.no-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  border-radius: 4px;
  padding: 10%;
  @include variables.blurry-background();

  &.xsmall {
    font-size: 0.75rem;
  }

  &.small {
    font-size: 0.875rem;
  }

  &.normal {
    font-size: 1rem;
  }

  &.large {
    font-size: 1.125rem;
  }

  &.xlarge {
    font-size: 1.25rem;
  }

  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 50%;
    padding: 1em;
    background: colors.$beige_dark;
    border-radius: 50%;

    svg.ant-empty-img-simple {
      width: 4em;
      height: 4em;

      .ant-empty-img-simple-ellipse {
        fill: colors.$gray;
      }

      .ant-empty-img-simple-g {
        stroke: colors.$beige_extra_light;
      }
    }
  }

  .label {
    margin-top: 1em;
    font-size: 1em;
  }
}
