@use 'src/shared/styles/colors.scss';
@use 'src/shared/styles/main.scss';
@use 'src/shared/styles/mediaStyles.scss';

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 3rem 0;
  font-size: 1rem;

  &.browser {
    position: absolute;
    bottom: 0;
  }

  .phone-container {
    display: flex;
    align-items: center;
    color: colors.$black_dark;
  }

  .phone-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    background-color: colors.$beige_dark;
    border-radius: 50%;
    margin-right: 4px;
  }

  .phone-icon {
    color: colors.$teal;
  }
}

@media (max-width: (mediaStyles.$desktop - 1)) {
  .footer-container {
    font-size: 1.15rem;
  }
}

@media (max-width: (mediaStyles.$largerPhone - 1)) {
  .footer-container {
    font-size: 1.35rem;
  }
}
