@use 'sass:color';
@use 'src/shared/styles/colors.scss';
@use 'src/shared/styles/variables.scss';

.main-menu {
  &.mobile {
    position: fixed;
    bottom: 0;
    transition: bottom 0.3s;
    width: 100%;

    &.hide {
      bottom: -1 * variables.$menuMobileHeight;
    }

    .mobile-menu {
      background-color: colors.$beige_light;
      min-height: variables.$menuMobileHeight;

      .mobile-menu__item {
        color: colors.$black_light;

        &.adm-tab-bar-item-active {
          color: colors.$teal;
        }
      }
    }
  }

  .browser-menu {
    background: none;
    border: none;
    font-size: 1rem;

    .browser-menu__item {
      padding-left: 3.75em !important;
      height: 3.5em;
      margin: 0 !important;

      .ant-menu-item-icon {
        color: colors.$black_light !important;
        font-size: 1.125em;
      }

      .ant-menu-title-content {
        color: colors.$black_light !important;
      }

      &.ant-menu-item-active {
        background-color: color.adjust(colors.$black_dark, $alpha: -0.95);
      }

      &.ant-menu-item-selected {
        background-color: colors.$beige_extra_light;

        &::after {
          border-color: colors.$teal_light;
        }
      }

      &.hasBorder {
        border-top: 1px solid color.adjust(colors.$black_dark, $alpha: -0.8);
      }
    }
  }
}
