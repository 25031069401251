@use 'src/shared/styles/mediaStyles.scss';
@use 'src/shared/styles/colors.scss';

.logout-container {
  display: flex;
  align-items: center;
  cursor: pointer;

  .logout-icon {
    font-size: 18px;
    margin-right: 8px;
  }

  .logout-label {
    font-size: 16px;
  }

  &:hover {
    color: colors.$gray;
  }
}

@media (max-width: (mediaStyles.$largerPhone - 1)) {
  .logout-container {
    .logout-icon {
      font-size: 22px;
    }
  }
}
